const STICKY_CLASS = 'Header--sticky';
const headerOffset = 50;
const header = document.querySelector('.Header');

const toggleSticky = () => {
    window.scrollY > headerOffset
        ? header.classList.add(STICKY_CLASS)
        : header.classList.remove(STICKY_CLASS);
};

window.addEventListener('scroll', toggleSticky);
