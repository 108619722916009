import $ from 'jquery';

const isAddToCartButton = (formData) => {
    let isValid = false;

    $.each(formData, (index, data) => {
        if (data.name === 'add-to-cart') {
            isValid = true;
        }
    });

    return isValid;
};

const addToCart = (addToCartButton, formData) => {
    $(document.body).trigger('adding_to_cart', [addToCartButton, formData]);

    $.ajax({
        type: 'POST',
        data: $.param(formData),
        url: jsData.ajaxUrl,
        success: (response) => {
            addToCartButton.removeClass('is-adding');
            $(document.body).trigger('added_to_cart');
        },
    });
};

$(document).on('submit', 'form.cart', (ev) => {
    const form = $(ev.target);
    const addToCartBtn = form.find('button[type="submit"]');

    addToCartBtn.addClass('is-adding');

    const formData = form.serializeArray();
    if (
        addToCartBtn.attr('name') &&
        addToCartBtn.attr('name') === 'add-to-cart' &&
        addToCartBtn.attr('value')
    ) {
        formData.push({ name: 'add-to-cart', value: addToCartBtn.attr('value') });
    }

    if (isAddToCartButton(formData)) {
        ev.preventDefault();
    } else {
        return;
    }

    formData.push({ name: 'action', value: 'kreacja_add_to_cart' });

    addToCart(addToCartBtn, formData);
});

$(document).on('click', '.ajax_add_to_cart', (ev) => {
    ev.preventDefault();

    addToCart($(ev.currentTarget), [
        {
            name: 'quantity',
            value: $(ev.currentTarget).attr('data-quantity'),
        },
        {
            name: 'action',
            value: 'kreacja_add_to_cart',
        },
        {
            name: 'add-to-cart',
            value: $(ev.currentTarget).attr('data-product_id'),
        },
    ]);
});
